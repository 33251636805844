import React from 'react'
import { Link } from 'i18n/Link'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import pricing from '../assets/icons/pricing.svg'
import about from '../assets/icons/about.svg'
import truck from '../assets/icons/truck.svg'
import bgImagePortrait from 'indexBackground.jpg'
import LargeBackground from 'LargeBackground.jpg'
import { breakpoints } from '../components/ui-kit/globalValue'
import { Page } from '../components/layout/Page'
import { Container } from '../components/ui-kit/Container'
import { headerHeights } from '../components/ui-kit/globalValue'
import { Title } from '../components/ui-kit/title'
import { colors } from 'components/ui-kit/globalValue'
import { useTranslations } from 'i18n/useTranslations'

const IndexMain = styled.div`
    height: calc(100vh - ${headerHeights.mobile});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 518px;

    @media (orientation: portrait) {
        background-image: url(${bgImagePortrait});
    }
    @media (orientation: landscape) {
        background-image: url(${LargeBackground});
    }
    @media (min-width: ${breakpoints.tablet}) {
        height: calc(100vh - ${headerHeights.tablet});
    }
    @media (min-width: 1200px) {
        height: calc(100vh - ${headerHeights.desktop});
    }
`

const ContainerIndex = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    color: ${colors.mobileColor};
`
const indexSVG = (props) => `
    padding-bottom: 3px;
    height: 75px;
    width: 75px;
    fill: ${colors.mobileColor};
    @media (min-width: ${breakpoints.tablet}) {
        height: 120px;
        width: 120px;
    }
`
const PriceSvg = styled(pricing)`
    ${indexSVG}
`
const AboutSvg = styled(about)`
    ${indexSVG}
`
const TruckSvg = styled(truck)`
    ${indexSVG}
`
const ButtonTitle = styled.h3`
    font-size: 24px;
    font-weight: 400;
    color: ${colors.mobileColor};
    @media (min-height: 660px) {
        font-size: 30px;
    }
`

const IndexTitle = styled(Title)`
    && {
        margin: 0.5em 0;
    }
    text-shadow: 2px 2px 2px #222;
    color: ${colors.mobileColor};
`

const ButtonIndexWraper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: ${breakpoints.tablet}) {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
`

const ButtonIndex = styled(Link)`
    padding: 5px;
    text-align: center;
    text-decoration: none;
    text-shadow: 2px 2px 2px #222;
    @media (min-height: 660px) {
        padding: 10px;
    }
    :hover {
        opacity: 0.7;
    }
`

const IndexPage = () => {
    const translations = useTranslations()
    return (
        <Page>
            <Helmet>
                <title>
                    {translations.index} - {translations.logo} -
                    {translations.siteTitle}
                </title>
                <meta
                    name="description"
                    content="СПСК - Скала-подільський Спецкарʼєр.
                    "
                />
            </Helmet>
            <IndexMain alt="Фонове зображення">
                <ContainerIndex>
                    <IndexTitle as="h1">{translations.siteTitle}</IndexTitle>
                    <ButtonIndexWraper>
                        <ButtonIndex to="/about/">
                            <AboutSvg />
                            <ButtonTitle>{translations.about}</ButtonTitle>
                        </ButtonIndex>
                        <ButtonIndex to="/product/">
                            <TruckSvg />
                            <ButtonTitle>{translations.products}</ButtonTitle>
                        </ButtonIndex>
                        <ButtonIndex to="/">
                            <PriceSvg />
                            <ButtonTitle>
                                {translations.requestPrice}
                            </ButtonTitle>
                        </ButtonIndex>
                    </ButtonIndexWraper>
                </ContainerIndex>
            </IndexMain>
        </Page>
    )
}
export default IndexPage
